import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { AngularFireFunctions } from '@angular/fire/functions';

import { environment } from '../../../../../../environments/environment';
import { User } from '../../plex/users/user.model';
import { AuditLogService } from '../../plex/audit-log/audit-log.service';
import { WodApiService } from '../services/wod-api.service';
import { WhitfieldsCallLampyService } from '../whitfields-call-lampy.service';
import { Entity } from '@plex/entities/entities.model';
import { BillingComponent } from '../dashboards/entity-dashboard/entity-dashboard-finance/components/billing/billing.component';
import { TypesenseService } from 'src/app/_shared/typesense/typesense.service';

import { AccountSummaryI } from './accounts/debtors-account-summary/account-summary-model';

interface AdvertisingScheduleReport {
	created: Date | firebase.firestore.Timestamp;
	createdBy: string;
	createdByName: string;
	createdByEmail: string;
	status: 'queue' | 'busy' | 'error' | 'done';
	error?: string;
	file?: {
		bucketPath: string;
		name: string;
		url: string;
	};
	task?: {
		taskURL: string;
	};
	cloudTask?: {
		name: string;
		docPath: string;
	};
}

interface NewAccountsReport {
	entity: Entity;
	currentUser: User;
	sendEmail: boolean;
	environment: EnvDetails;
}

interface InterestBillingNewPastelReport {
	schemes: any;
	endYearMonth: string;
	reportType: string;
	currentUser: User;
	sendEmail: boolean;
	environment: EnvDetails;
}

export interface WodAccount {
	account: string;
	accountLink?: string;
	description: string;
	type: string;
}

@Injectable({
	providedIn: 'root',
})
export class MReportsService {
	currentSchemeBalances = new BehaviorSubject(undefined);

	constructor(
		private wodApiService: WodApiService,
		public afs: AngularFirestore,
		private auditLogService: AuditLogService,
		private whitfieldsCallLampyService: WhitfieldsCallLampyService,
		private functions: AngularFireFunctions,
		private typesenseService: TypesenseService
	) {}

	public generateAdvertisingScheduleReport({ uid, firstname, surname, email }: User) {
		return this.afs.collection<AdvertisingScheduleReport>('entities/whitfields/advertisingScheduleReports').add({
			created: new Date(),
			status: 'queue',
			createdBy: uid,
			createdByName: `${firstname} ${surname}`,
			createdByEmail: email,
		});
	}

	public async generateNewAccountsReport(entity: Entity, loggedInUser: User) {
		const { admin, client, product } = environment;
		return this.functions
			.httpsCallable<NewAccountsReport>('ff-reports-generateNewAccountsReport')({
				entity: entity,
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
			})
			.toPromise();
	}

	public async generateInterestBillingNewPastelReport(data, loggedInUser: User) {
		const { admin, client, product } = environment;
		return this.functions
			.httpsCallable<InterestBillingNewPastelReport>('ff-reports-generateInteresBillingNewPastelReport')({
				...data,
				currentUser: loggedInUser,
				sendEmail: false,
				environment: { admin, client, product },
			})
			.toPromise();
	}

	public async runPrsPaymentExport() {
		const data = {
			sendEmail: false,
		};
		return this.functions.httpsCallable('prs-runPrsPaymentExportFunction')(data).toPromise();
	}

	public getLatestAdvertisingScheduleReport() {
		return this.afs
			.collection<AdvertisingScheduleReport>('entities/whitfields/advertisingScheduleReports', ref => ref.orderBy('created', 'desc').limit(1))
			.valueChanges()
			.pipe(map(reports => reports.map(({ created, ...rest }) => ({ ...rest, created: (created as firebase.firestore.Timestamp).toDate() }))));
	}

	async getAccounts(prefix: string, useNewPastel?: boolean): Promise<WodAccount[]> {
		if (useNewPastel) {
			const { accounts } = await this.whitfieldsCallLampyService.getLampyAccounts(prefix, useNewPastel);
			return accounts.map(({ account_description, ...rest }) => {
				return { ...rest, description: account_description };
			});
		}

		return this.wodApiService.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/accounts/list.pl?prefix=${prefix}`).toPromise() as Promise<WodAccount[]>;
	}

	getAgeAnalysis(url: string) {
		return this.wodApiService.getData(url);
	}

	getBalanceReport(prefix, date) {
		return this.wodApiService.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/reports/balances.pl?prefix=${prefix}`);
	}

	getBondHolderReport(prefix, date) {
		return this.wodApiService.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/reports/bondholders.pl?prefix=${prefix}`);
	}

	getTrialBalance(prefix, date, selectedMonth, finYearEnd) {
		const months = {
			january: 1,
			february: 2,
			march: 3,
			april: 4,
			may: 5,
			june: 6,
			july: 7,
			august: 8,
			september: 9,
			october: 10,
			november: 11,
			december: 12,
		};
		const splitSelectedMonthYear = selectedMonth.split(/(\s+)/);
		const convertToDate = new Date(splitSelectedMonthYear[1], splitSelectedMonthYear[0] - 1);
		const getLastDayOfMonth = moment(convertToDate).endOf('month').format('DD');
		if (finYearEnd.toLowerCase()) {
			return this.wodApiService.getData(
				`https://plexapi.whitfields.co.za/cgi-bin/complex/reports/trialbalance.pl?prefix=${prefix}&date=${getLastDayOfMonth} ${selectedMonth}&financialYearStart=${
					months[finYearEnd.toLowerCase()] + 1
				}`
			);
		} else {
			return of({ error: 'No Financial year end' });
		}
	}

	getTermsAndConditions(prefix) {
		return this.wodApiService.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/reports/termsandconditions.pl?prefix=${prefix}`);
	}

	getGeneralLedger(start, end, prefix, accounts) {
		return this.wodApiService.postData(`https://plexapi.whitfields.co.za/cgi-bin/complex/accounts/details.pl`, { start: start, end: end, prefix: prefix, accounts: accounts });
	}

	async getFireflyCurrentBalances(prefix, accounts, options) {
		const balances = await this.whitfieldsCallLampyService.getLampySchemeBalances(prefix, accounts, options.endDate, options.buffer, options.amountBuffer, options.overdraft);
		this.currentSchemeBalances.next({ accounts: balances.accountBalances, available: balances.availableBalance });
		return { accounts: balances.accountBalances, available: balances.availableBalance };
		// return Promise.resolve();
	}

	async getFireflyTrialBalance(prefix, selectedMonth, finYearEnd) {
		const months = {
			january: 1,
			february: 2,
			march: 3,
			april: 4,
			may: 5,
			june: 6,
			july: 7,
			august: 8,
			september: 9,
			october: 10,
			november: 11,
			december: 12,
		};
		const splitSelectedMonthYear = selectedMonth.split(/(\s+)/);
		const convertToDate = new Date(splitSelectedMonthYear[1], splitSelectedMonthYear[0] - 1);
		const getLastDayOfMonth = moment(convertToDate).endOf('month').format('DD');
		if (finYearEnd.toLowerCase()) {
			const result = await this.whitfieldsCallLampyService.getLampyTrialBalance(prefix, `${getLastDayOfMonth} ${selectedMonth}`, months[finYearEnd.toLowerCase()] + 1);
			return result;
		} else {
			return Promise.reject({ error: 'No Financial year end' });
		}
	}
	fetchStatementsProgress() {
		return this.afs.collection(`entities/whitfields/statements/status/entities`).valueChanges({ idField: 'id' });
	}
	getCurrentBalances(prefix, accounts, options) {
		// environment.admin = 'amiti-plex-live'; // NOTE: For debugging
		const getAccountBalances = () =>
			new Promise((res, rej) => {
				const accountList = [];
				accounts.forEach(account => {
					this.wodApiService
						.getData(`https://plexapi.whitfields.co.za/cgi-bin/complex/accounts/balances.pl?prefix=${prefix}&account=${account}`)
						.subscribe((data: any) => {
							accountList.push(data);
							if (accountList.length === accounts.length) {
								res(accountList);
							}
						});
				});
			});

		const getAvailableBalance = amount8400 =>
			new Promise((res, rej) => {
				if (amount8400) {
					this.wodApiService
						.getData(
							`https://plexapi.whitfields.co.za/complex/availablebalance.php?prefix=${prefix}&amount8400=${amount8400}&buffer=${
								options.bufferOverride
							}&amountBuffer=${options.buffer || '0'}&overdraft=${options.overdraft}`
						)
						.subscribe(data => {
							res(data);
						});
				} else {
					//{"paid":"2000","nextMonthDate":"2020-03-31","to pay":"194.85","to pay break down":[194.85],"overdraft":0,"bufferAmount":"0","total":"-12194.85"}
					const data = {
						paid: '0.00',
						nextMonthDate: '2020-03-31',
						'to pay': '0.00',
						'to pay break down': [194.85],
						overdraft: options.overdraft,
						bufferAmount: '0.00',
						total: '0.00',
					};
					res(data);
				}
			});

		const final = async () => {
			if (prefix) {
				if (!environment.admin.includes('dev')) {
					const accountBalances: any = await getAccountBalances();
					const get8400 = await accountBalances.filter(account => account.account === '8400')[0];
					let availablebalance: any = 0;
					if (get8400) {
						availablebalance = await getAvailableBalance(get8400['total'] ? get8400['total'] : null);
					}
					let data = {
						accounts: accountBalances,
						available: availablebalance,
					};
					return data;
				} else {
					return {
						accounts: [
							{
								endDate: '26/8/2021',
								account: '8403',
								total: '-10480.98',
							},
							{
								endDate: '26/8/2021',
								account: '8405',
								total: '0.60',
							},
							{
								endDate: '26/8/2021',
								account: '8407',
								total: '141128.68',
							},
							{
								endDate: '26/8/2021',
								account: '8410',
								total: '141128.68',
							},
						],
						available: {
							paid: '0',
							nextMonthDate: '2021-08-31',
							'to pay': '0',
							'to pay break down': [],
							overdraft: options.overdraft,
							bufferAmount: '0',
							total: '-20480.98',
						},
					};
				}
			}
			return null;
		};
		return final();
	}

	private genRefNo(taskType: string, prefix: string): string {
		const scheme = prefix !== 'WPM' ? prefix : 'FIREFLY';
		const splitType = taskType.split('');
		const type = `${splitType[0]}${splitType[1]}${splitType[2]}`.toUpperCase();

		return `${scheme}-${type}-${Math.floor(new Date().valueOf() * Math.random())
			.toString()
			.substring(2, 10)}`;
	}

	saveTask(user, type, title, prefix?, data?) {
		const taskId = this.afs.createId();
		const month = moment().format('MMMM YYYY');
		let task: any = {
			prefix: prefix !== '' ? prefix : '',
			request: 'add' + type,
			subject: title.subject,
			description: title.description !== '' ? title.description : 'Here is the ' + title.subject + ' csv file for ' + month,
			id: taskId,
			entityId: 'whitfields',
			active: true,
			refNo: this.genRefNo('General', 'WPM'),
			privacy: 'Restricted',
			managerId: user.uid,
			creatorId: user.uid,
			creatorName: `${user.firstname} ${user.surname}`,
			status: 'Submitted',
			tags: [],
			created: new Date(),
			updated: new Date(),
			taskType: 'General',
			taskTeam: [user.uid],
			watchers: [],
			properties: [],
			accounts: [],
			files: [],
			category: '',
			priority: 'High',
			OnHold: false,
			email: user.email,
		};
		if (type === 'LevyAdvanceBalance' || type === 'AdvanceReportMonthEnd') {
			task.day = data.day ? data.day : moment().format('DD');
			task.month = data.month;
			task.year = data.year;
		} else {
			task.day = moment().format('DD');
			task.month = moment().format('MMMM');
			task.year = moment().format('YYYY');
		}
		task.createdBy = task.creatorId;
		task.createdByName = task.creatorName ? task.creatorName : '';
		let logData = {
			name: `${type} report`,
			description: `${user.firstname} ${user.surname} requested ${type} report.`,
			type: 'added',
			category: 'reports',
			created: Date.now(),
			reportType: type,
		};
		return this.afs
			.doc(`pending/${task.id}`)
			.set(task)
			.then(() => {
				return this.auditLogService.addAudit(logData, 'whitfields');
			});
		//return Promise.resolve()
	}

	saveCSV() {}

	async handleDebtorsAccountSummary(entityId, selectedMonthYear): Promise<AccountSummaryI> {
		let dateSplit = selectedMonthYear.split(' ');
		let startDate = `${dateSplit[1]}-${+dateSplit[0] - 1}-22`;
		let endDate = `${dateSplit[1]}-${dateSplit[0]}-21`;
		// let formatDate = moment(startDate).format('YYYY-MM-DD');
		// console.log(`CJ ~ formatDate`, formatDate);

		// return Promise.resolve([]);
		const accounts = [];
		let totals = { accountNumber: 'Totals', registeredOwner: '', opening: '', charges: '', receipts: '', closing: '' };
		const getAccounts = await this.afs.collection(`entities/${entityId}/fin/debtors/list`).ref.where('isFirefly', '==', true).orderBy('name', 'asc').get();
		for await (let accountDoc of getAccounts.docs) {
			let accountData: any = accountDoc.data();
			let account = { accountNumber: accountData.name, registeredOwner: '' };

			const accountRegisteredOwners = await this.afs.collection(`entities/${entityId}/fin/debtors/list/${accountDoc.id}/registered_owners`).ref.limit(1).get();
			accountRegisteredOwners.forEach((ownerDoc: any) => {
				account['registeredOwner'] = ownerDoc.data().name || '';
			});

			const getAccountLampy = await this.whitfieldsCallLampyService.getLampyFireflySchemeAccountSummary(account.accountNumber, startDate, endDate);
			account['opening'] = +getAccountLampy.balance.opening;
			account['charges'] = +getAccountLampy.balance.debit;
			account['receipts'] = +getAccountLampy.balance.credit;
			account['closing'] = +getAccountLampy.balance.balance;
			if (account['receipts'] > 0 || account['charges'] > 0) {
				if (account['closing'] > 0) {
					account['opening'] = account['opening'].toFixed(2);
					account['charges'] = account['charges'].toFixed(2);
					account['receipts'] = account['receipts'].toFixed(2);
					account['closing'] = account['closing'].toFixed(2);
					accounts.push(account);
				}
			}
		}
		totals.opening = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.opening;
			}, 0)
			.toFixed(2);
		totals.charges = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.charges;
			}, 0)
			.toFixed(2);
		totals.receipts = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.receipts;
			}, 0)
			.toFixed(2);
		totals.closing = accounts
			.reduce((accumulator, obj) => {
				return accumulator + +obj.closing;
			}, 0)
			.toFixed(2);
		let returnData: AccountSummaryI = { accounts: accounts, totals: totals, date: '' };
		return returnData;
	}

	async getStatementBatches(per_page: number, page: number, q: string = '*', query_by: string) {
		const { hits, found } = await this.typesenseService.searchCollection(`${environment.firebase.projectId}-statementBatch`, {
			q,
			query_by,
			page,
			per_page,
		});

		return { batches: hits.map(({ document }) => document), found };
	}

	async getStatementBatchLogs(entityId, batchId) {
		return await this.afs
			.collection(`/entities/whitfields/logs/statements/entities/${entityId}/batches/${batchId}/logs`)
			.ref.orderBy('created', 'desc')
			.get()
			.then((logs: any) => {
				return logs.docs.map((log: any) => {
					const logData = log.data();
					logData['id'] = log.id;
					return logData;
				});
				//return logs.docs;
			});
	}

	async getStatementBatch(entityId, batchId) {
		return await this.afs
			.doc(`/entities/whitfields/logs/statements/entities/${entityId}/batches/${batchId}`)
			.ref.get()
			.then((batch: any) => {
				return batch.data();
			});
	}

	sendStatementsLampy(data) {
		return this.afs.doc(`entities/whitfields/statements/status/entities/${data.entityId}`).set(data);
	}

	getStatementsLampySending() {
		return this.afs.collection(`entities/whitfields/statements/status/entities`).valueChanges();
	}
}
