import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';
import { TaskType } from '@models/tasks';
import { User } from '@plex/users/user.model';

interface EntityBankAccountMetadata {
	accountNumber?: string;
	id?: string;
}

@Injectable({
	providedIn: 'root',
})
export class DashboardsService {
	entityId: string;

	constructor(public afs: AngularFirestore, private store: Store) {
		this.store.select(selectEntityId).subscribe(entityId => {
			this.entityId = entityId;
		});
	}

	getUserCounts() {
		return this.afs.collection(`entities/${this.entityId}/users`, ref => ref.where('active', '==', true)).valueChanges();
	}

	getPropertyCounts() {
		return this.afs.collection(`entities/${this.entityId}/properties`, ref => ref.where('active', '==', true)).valueChanges();
	}

	getEntityManagementTeam() {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.where('active', '==', true).orderBy('firstname', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	getEntityWhitfieldsManagementTeam() {
		return this.afs
			.collection(`entities/${this.entityId}/WhitfieldsManagementTeam`, ref => ref.where('active', '==', true).orderBy('firstname', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	getEntityWhitfieldsProfessionalTeam() {
		return this.afs.collection(`entities/${this.entityId}/WhitfieldsProfessionalTeam`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	getServiceProviders() {
		return this.afs.collection(`entities/${this.entityId}/serviceProviders`, ref => ref.where('active', '==', true)).valueChanges({ idField: 'id' });
	}

	getServiceProviderDetails(serviceProviderId) {
		return this.afs.doc(`entities/${serviceProviderId}`).valueChanges();
	}

	getServiceProviderContacts(serviceProviderId) {
		return this.afs
			.collection(`entities/${serviceProviderId}/users/`)
			.ref.where('active', '==', true)
			.get()
			.then((users: any) => {
				return users.docs.map((user: any) => {
					const userData = user.data();
					userData['id'] = user.id;
					return userData;
				});
			});
	}

	getServiceProviderManagementUsers(entityId: string) {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`)
			.ref.where('active', '==', true)
			.orderBy('firstname')
			.get()
			.then((users: any) => {
				return users.docs.map((user: any) => {
					const userData = user.data();
					userData['id'] = user.id;
					return userData;
				});
			});
	}

	async getStaff(type: string): Promise<any[]> {
		let usersList = [];
		await this.afs
			.collection('entities/whitfields/users')
			.ref.where('active', '==', true)
			.where('permissions', 'array-contains', type)
			.orderBy('firstname')
			.get()
			.then(async (users: any) => {
				for await (const entityUserDoc of users.docs) {
					const userDoc = await this.afs.doc(`users/${entityUserDoc.id}`).ref.get();
					let user = userDoc.data() as User;
					const userData = entityUserDoc.data();
					userData['id'] = entityUserDoc.id;
					userData['tel'] = user.tel || '';
					usersList.push(userData);
				}
			});
		return usersList;
	}

	// SCHEME EXECUTIVES
	updateSchemeExecutivePortfolios(userId: string, portfolios: any) {
		return this.afs
			.doc(`entities/${this.entityId}/management/users/list/${userId}`)
			.ref.get()
			.then((user: any) => {
				const userData = user.data();
				const existingPortfolios = userData.profiles ? userData.profiles : [];
				portfolios.forEach((portfolio: string) => {
					if (existingPortfolios.indexOf(portfolio) === -1) {
						existingPortfolios.push(portfolio);
					}
				});

				return this.afs
					.doc(`entities/${this.entityId}/management/users/list/${userId}`)
					.set(
						{
							profiles: existingPortfolios,
						},
						{ merge: true }
					)
					.then(() => {
						this.afs
							.doc(`users/${userId}/entities/${this.entityId}`)
							.ref.get()
							.then((userEntity: any) => {
								const userEntityData = userEntity.data();
								const existingPermissions = userEntityData.permissions ? userEntityData.permissions : [];
								portfolios.forEach((portfolio: string) => {
									const formattedPortfolio = portfolio.toLowerCase().replace(/ /g, '_');
									if (existingPermissions.indexOf(formattedPortfolio) === -1) {
										existingPermissions.push(formattedPortfolio);
									}
								});

								return this.afs.doc(`users/${userId}/entities/${this.entityId}`).set(
									{
										permissions: existingPermissions,
									},
									{ merge: true }
								);
							});
					});
			});
	}

	removeSchemeExecutivePortfolio(userId: string, portfolio: string) {
		return this.afs
			.doc(`entities/${this.entityId}/management/users/list/${userId}`)
			.ref.get()
			.then((user: any) => {
				const userData = user.data();
				const existingPortfolios = userData.profiles ? userData.profiles : [];
				if (existingPortfolios.indexOf(portfolio) !== -1) {
					existingPortfolios.splice(existingPortfolios.indexOf(portfolio), 1);
				}

				return this.afs
					.doc(`entities/${this.entityId}/management/users/list/${userId}`)
					.set(
						{
							profiles: existingPortfolios,
						},
						{ merge: true }
					)
					.then(() => {
						this.afs
							.doc(`users/${userId}/entities/${this.entityId}`)
							.ref.get()
							.then((userEntity: any) => {
								const userEntityData = userEntity.data();
								const existingPermissions = userEntityData.permissions ? userEntityData.permissions : [];
								const formattedPortfolio = portfolio.toLowerCase().replace(/ /g, '_');
								if (existingPermissions.indexOf(formattedPortfolio) !== -1) {
									existingPermissions.splice(existingPermissions.indexOf(formattedPortfolio), 1);
								}

								return this.afs.doc(`users/${userId}/entities/${this.entityId}`).set(
									{
										permissions: existingPermissions,
									},
									{ merge: true }
								);
							});
					});
			});
	}

	removeSchemeExecutive(id: string, profiles: any) {
		const removeManagementTeam = this.afs.doc(`entities/${this.entityId}/management/users/list/${id}`).set(
			{
				active: false,
				isAdmin: false,
				isExecutiveManagingAgent: false,
				isSchemeExecutive: false,
				profiles: [],
			},
			{ merge: true }
		);

		const removeUserEntity = this.afs
			.doc(`users/${id}/entities/${this.entityId}`)
			.ref.get()
			.then((userEntity: any) => {
				const userEntityData = userEntity.data();
				let permissions = userEntityData.permissions ? userEntityData.permissions : [];

				const sxPermissions = ['admin', 'scheme_executive', 'scheme_executive_owner', 'scheme_executive_non_owner', 'executive_managing_agent'];

				sxPermissions.forEach((permission: string) => {
					if (permissions.indexOf(permission) !== -1) {
						permissions.splice(permissions.indexOf(permission), 1);
					}
				});

				profiles.forEach((portfolio: string) => {
					const portfolioFormatted = portfolio.toLowerCase().replace(/ /g, '_');
					if (permissions.indexOf(portfolioFormatted) !== -1) {
						permissions.splice(permissions.indexOf(portfolioFormatted), 1);
					}
				});

				return this.afs.doc(`users/${id}/entities/${this.entityId}`).set(
					{
						permissions,
					},
					{ merge: true }
				);
			});

		return Promise.all([removeManagementTeam, removeUserEntity]);
	}

	// ON-SITE TEAM
	updateOnSiteTeamsPositions(userId: string, positions: any) {
		return this.afs
			.doc(`entities/${this.entityId}/management/users/list/${userId}`)
			.ref.get()
			.then((user: any) => {
				const userData = user.data();
				let isAdmin = userData.isAdmin ? userData.isAdmin : false;
				let isSchemeManager = userData.isSchemeManager ? userData.isSchemeManager : false;
				const existingPositions = userData.profiles ? userData.profiles : [];
				positions.forEach((position: string) => {
					if (existingPositions.indexOf(position) === -1) {
						if (position === 'Administrator') {
							isAdmin = true;
						} else if (position === 'Scheme Manager') {
							isSchemeManager = true;
						} else {
							existingPositions.push(position);
						}
					}
				});

				return this.afs
					.doc(`entities/${this.entityId}/management/users/list/${userId}`)
					.set(
						{
							profiles: existingPositions,
							isAdmin,
							isSchemeManager,
						},
						{ merge: true }
					)
					.then(() => {
						this.afs
							.doc(`users/${userId}/entities/${this.entityId}`)
							.ref.get()
							.then((userEntity: any) => {
								const userEntityData = userEntity.data();
								const existingPermissions = userEntityData.permissions ? userEntityData.permissions : [];
								positions.forEach((position: string) => {
									if (position === 'Administrator') {
										position = 'admin';
									}
									const formattedPortfolio = position.toLowerCase().replace(/ /g, '_');
									if (existingPermissions.indexOf(formattedPortfolio) === -1) {
										existingPermissions.push(formattedPortfolio);
									}
								});

								return this.afs.doc(`users/${userId}/entities/${this.entityId}`).set(
									{
										permissions: existingPermissions,
									},
									{ merge: true }
								);
							});
					});
			});
	}

	removeOnSiteTeamsPositions(userId: string, position: string) {
		return this.afs
			.doc(`entities/${this.entityId}/management/users/list/${userId}`)
			.ref.get()
			.then((user: any) => {
				const userData = user.data();
				let isAdmin = userData.isAdmin ? userData.isAdmin : false;
				let isSchemeManager = userData.isSchemeManager ? userData.isSchemeManager : false;
				const existingPositions = userData.profiles ? userData.profiles : [];
				if (existingPositions.indexOf(position) !== -1) {
					existingPositions.splice(existingPositions.indexOf(position), 1);
				}
				if (position === 'admin') {
					isAdmin = false;
				}
				if (position === 'Scheme Manager') {
					isSchemeManager = false;
				}

				return this.afs
					.doc(`entities/${this.entityId}/management/users/list/${userId}`)
					.set(
						{
							profiles: existingPositions,
							isAdmin,
							isSchemeManager,
						},
						{ merge: true }
					)
					.then(() => {
						this.afs
							.doc(`users/${userId}/entities/${this.entityId}`)
							.ref.get()
							.then((userEntity: any) => {
								const userEntityData = userEntity.data();
								const existingPermissions = userEntityData.permissions ? userEntityData.permissions : [];
								const formattedPosition = position.toLowerCase().replace(/ /g, '_');
								if (existingPermissions.indexOf(formattedPosition) !== -1) {
									existingPermissions.splice(existingPermissions.indexOf(formattedPosition), 1);
								}

								return this.afs.doc(`users/${userId}/entities/${this.entityId}`).set(
									{
										permissions: existingPermissions,
									},
									{ merge: true }
								);
							});
					});
			});
	}

	removeOnSiteTeam(id: string, positions: any) {
		const removeManagementTeam = this.afs.doc(`entities/${this.entityId}/management/users/list/${id}`).set(
			{
				active: false,
				isAdmin: false,
				isSchemeManager: false,
				profiles: [],
			},
			{ merge: true }
		);

		const removeUserEntity = this.afs
			.doc(`users/${id}/entities/${this.entityId}`)
			.ref.get()
			.then((userEntity: any) => {
				const userEntityData = userEntity.data();
				let permissions = userEntityData.permissions ? userEntityData.permissions : [];

				const onSiteTeamPermissions = ['admin', 'scheme_manager'];

				onSiteTeamPermissions.forEach((permission: string) => {
					if (permissions.indexOf(permission) !== -1) {
						permissions.splice(permissions.indexOf(permission), 1);
					}
				});

				positions.forEach((position: string) => {
					const positionFormatted = position.toLowerCase().replace(/ /g, '_');
					if (permissions.indexOf(positionFormatted) !== -1) {
						permissions.splice(permissions.indexOf(positionFormatted), 1);
					}
				});

				return this.afs.doc(`users/${id}/entities/${this.entityId}`).set(
					{
						permissions,
					},
					{ merge: true }
				);
			});

		return Promise.all([removeManagementTeam, removeUserEntity]);
	}

	// ADMINISTRATIVE TEAM
	updateAdministrativeTeam(id: string, formData: any) {
		const { email = '', firstname = '', surname = '', cell = '', tel = '' } = formData.user;
		const defaultUserDetails = {
			active: true,
			email,
			firstname,
			surname,
			tel,
		};

		const uid = formData.user.id;
		return this.afs
			.doc(`entities/${this.entityId}/staff/${uid}`)
			.set({ ...defaultUserDetails, uid }, { merge: true })
			.then(() => {
				const updateManagementTeam = this.afs.doc(`entities/${this.entityId}/WhitfieldsManagementTeam/${id}`).set(
					{
						...defaultUserDetails,
						cell,
						tel,
						company: '',
						created: Date.now(),
						entityId: this.entityId,
						ref: id ? this.afs.doc(`users/${id}`).ref : '',
						type: id,
						uid,
					},
					{ merge: true }
				);
				const updateUserEntity = this.afs.doc(`users/${uid}/entities/${this.entityId}`).set({ active: true }, { merge: true });
				return Promise.all([updateManagementTeam, updateUserEntity]);
			});
	}

	removeAdministrativeTeam(id: string, uid: string) {
		const markUserEntityAsInactive = this.afs.doc(`users/${uid}/entities/${this.entityId}`).update({ active: false });
		const deleteEntityUser = this.afs.doc(`entities/${this.entityId}/WhitfieldsManagementTeam/${id}`).delete();
		return Promise.all([markUserEntityAsInactive, deleteEntityUser]);
	}

	// PROFESSIONAL TEAM

	updateProfessionalTeam(id: string, formData: any) {
		return this.afs.doc(`entities/${this.entityId}/WhitfieldsProfessionalTeam/${id}`).set(
			{
				active: true,
				contactNumber: formData.user.cell ? formData.user.cell : '',
				contactPerson: `${formData.user.firstname ? formData.user.firstname : ''}${formData.user.firstname ? ' ' : ''}${
					formData.user.surname ? formData.user.surname : ''
				}`,
				created: Date.now(),
				email: formData.user.email ? formData.user.email : '',
				name: formData.serviceProvider.name ? formData.serviceProvider.name : '',
				type: id,
				uid: formData.serviceProvider.id ? formData.serviceProvider.id : '',
			},
			{ merge: true }
		);
	}

	removeProfessionalTeam(id: string) {
		return this.afs.doc(`entities/${this.entityId}/WhitfieldsProfessionalTeam/${id}`).delete();
	}

	getEntityTaskTypeTaskLookups(entityId: string, taskType: TaskType) {
		const tasksRef = this.afs.collection('tasksLookup', ref => ref.where('entityId', '==', entityId).where('taskType', '==', taskType).where('status', '!=', 'Completed'));
		return tasksRef.valueChanges({ idField: 'uid' });
	}

	getEntityTaskTypeTaskLookupsAfterDate(entityId: string, taskType: TaskType, date: Date) {
		const tasksRef = this.afs.collection('tasksLookup', ref =>
			ref.where('entityId', '==', entityId).where('taskType', '==', taskType).where('status', '==', 'Completed').where('created', '>', date)
		);
		return tasksRef.valueChanges({ idField: 'uid' });
	}

	public async fetchEntityMetaWhitfieldsBankAccounts(entityId: string): Promise<EntityBankAccountMetadata[]> {
		return (await this.afs.collection<EntityBankAccountMetadata>(`entities/${entityId}/meta/whitfields/bank_accounts/`).get().toPromise()).docs.map(snapshot => ({
			id: snapshot.id,
			...snapshot.data(),
		}));
	}

	public updateEntityMetaWhitfieldsBankAccounts(entityId: string, property: string, value: any): Promise<void> {
		return this.afs.doc<EntityBankAccountMetadata>(`entities/${entityId}/meta/whitfields/bank_accounts/${property}`).set(
			{
				accountNumber: value,
			},
			{ merge: true }
		);
	}
}
